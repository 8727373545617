import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { TalentLocale } from '@models/locales';
import { User } from '@models/user.model';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { NotificationService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _ENDPOINT_URL = `${environment.API_URL}/core/users/me`;

  private _isAuthenticated: boolean;

  currentUserId = null;

  private _currentUser$$: BehaviorSubject<User | undefined | null> =
    new BehaviorSubject<User | undefined | null>(undefined);
  readonly currentUser$ = this._currentUser$$.asObservable();

  get currentUser() {
    return this._currentUser$$.value;
  }

  constructor(
    private readonly jwtHelper: JwtHelperService,
    private readonly _httpClient: HttpClient,
    private readonly _router: Router,
    private readonly _translocoService: TranslocoService,
    private readonly _notificationsService: NotificationService,
    private readonly _dateAdapter: DateAdapter<Date>,
  ) {}

  init() {
    this.getUserFromBearerToken$()
      .pipe(
        take(1),
        switchMap((user) => {
          if (!user) {
            this._currentUser$$.next(null);
            this._router.navigateByUrl('/404');
          }
          return of(user);
        }),
      )
      .subscribe((user) => {
        this._currentUser$$.next(user);
        this._isAuthenticated = !!user;
      });
  }

  refreshCurrentUser() {
    this.getUserFromBearerToken$()
      .pipe(take(1))
      .subscribe((user) => this._currentUser$$.next(user));
  }

  isLoggedIn(): Observable<boolean> {
    const token = this.jwtHelper.tokenGetter();

    if (!token) {
      return of(false);
    }

    const tokenExpired = this.jwtHelper.isTokenExpired(token);

    if (tokenExpired) {
      return of(false);
    } else {
      if (this._isAuthenticated) {
        return of(true);
      } else {
        this.init();
        return of(true);
      }
    }
  }

  logout() {
    window.location.href = environment.MY_SILAE_URL;
    localStorage.clear();
  }

  getToken(): string | undefined {
    return this.jwtHelper.tokenGetter();
  }

  /**
   * Uses embedded bearer token from jwtModule to get related Talent user
   * @returns User | undefined
   */
  getUserFromBearerToken$(): Observable<User | undefined> {
    return this._httpClient.get<User>(`${this._ENDPOINT_URL}`);
  }

  decodeToken(token: string) {
    return this.jwtHelper.decodeToken(token);
  }

  isTokenExpired(token) {
    return this.jwtHelper.isTokenExpired(token);
  }

  storeToken(token: string): void {
    localStorage.setItem('token', token);
  }

  storeTokenAndConnectionMethod(token: string): void {
    localStorage.setItem('lastConnectionMethod', this.decodeToken(token).auth);
    localStorage.setItem('token', token);
  }

  deleteToken(): void {
    localStorage.removeItem('token');
  }

  updateLocale$(userId: number, locale: TalentLocale) {
    return this._httpClient
      .patch(
        `${environment.API_URL}/core/users/${userId}`,
        { locale },
        { observe: 'response' },
      )
      .pipe(
        take(1),
        tap((success) => {
          if (!success) {
            this._notificationsService.showError('ALERTS.GENERIC_ERROR');
          }
          this._translocoService.setActiveLang(locale);
          this._dateAdapter.setLocale(locale);
          this.refreshCurrentUser();
        }),
      );
  }
}
