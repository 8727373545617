import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { CompanyService } from '@core/services/company.service';
import { FilesService } from '@core/services/files.service';
import { ModalsBaseService } from '@core/services/modals.service';
import { NotificationService } from '@core/services/notifications.service';
import {
  EDataMutation,
  EMeetingMutation,
  IPostHogEventConfig,
} from '@core/services/posthog.service';
import { environment } from '@env/environment';
import {
  EMeetingStepName,
  meetingTimelineAdminSteps,
} from '@meeting/components/config/meeting-timeline-steps';
import { Step } from '@meeting/components/meeting-survey-timeline-step/meeting-survey-timeline-step.component';
import { IPreviewTemplateModalConfig } from '@meeting/components/preview-template-modal/preview-template-modal.component';
import { MODAL_CONFIGS } from '@meeting/modals.config';
import {
  ITemplateQuestions,
  ITemplateResult,
} from '@meeting/models/meeting-template.model';
import {
  EParticipantRoles,
  IConductMeetingResult,
  ICreateMeeting,
  IFilterFindMeetings,
  IMeeting,
  IMeetingResult,
  IParticipant,
  IPatchMeeting,
  IPostMeetingConduct,
  IPrepareMeetingResult,
} from '@meeting/models/meeting.model';
import {
  IParticipantAnswerPrepare,
  IParticipantAnswerResult,
} from '@meeting/models/participant-answers.model';
import { MeetingTimelineService } from '@meeting/services/meeting-timeline.service';
import { Company } from '@models/company.model';
import * as qs from 'qs';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { MeetingTemplatesService } from './meeting-template.service';

export interface ISendDownloadedReportEventPayload {
  participant: IParticipant;
  company: Company;
}

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting`;
  private _meetings$$: BehaviorSubject<IMeeting[]> = new BehaviorSubject<
    IMeeting[]
  >([]);
  meetings$ = this._meetings$$.asObservable();

  // POSTHOG EVENT
  private _emitDownloadedReportEvent$$: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  sendDownloadedReportEvent$$: BehaviorSubject<
    ISendDownloadedReportEventPayload | undefined
  > = new BehaviorSubject<ISendDownloadedReportEventPayload | undefined>(
    undefined,
  );

  posthogEvent$$: BehaviorSubject<
    | {
        mutation: EDataMutation | EMeetingMutation;
        meeting: IMeetingResult | IPrepareMeetingResult;
        template: ITemplateResult;
      }
    | undefined
  > = new BehaviorSubject<{
    mutation: EDataMutation | EMeetingMutation;
    meeting: IMeetingResult | IPrepareMeetingResult;
    template: ITemplateResult;
  }>(undefined);

  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  loading$ = this._loading$$.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: NotificationService,
    private readonly _modalsService: ModalsBaseService,
    private readonly _filesService: FilesService,
    private readonly _authService: AuthenticationService,
    private readonly _companyService: CompanyService,
    private readonly _meetingTimelineService: MeetingTimelineService,
    private readonly _meetingTemplatesService: MeetingTemplatesService,
  ) {
    this._authService.currentUser$
      .pipe(distinctUntilChanged())
      .subscribe((user) => {
        user && this.refreshStore();
      });

    this._initEmitDownloadedReportEventListener();
  }

  private _initEmitDownloadedReportEventListener() {
    this._emitDownloadedReportEvent$$
      .pipe(
        switchMap((meetingId) => {
          if (!meetingId) return EMPTY;
          return this.getOne(meetingId).pipe(map((meeting) => meeting));
        }),
        switchMap((meeting) => {
          return combineLatest([
            this._authService.currentUser$.pipe(
              map((user) => ({ user, meeting })),
            ),
            this._companyService.currentCompany$,
          ]);
        }),
        tap(([{ user, meeting }, company]) => {
          const participant = meeting.participants.find(
            (p) => p.user.id === user.id,
          );

          this.sendDownloadedReportEvent$$.next({ participant, company });
        }),
      )
      .subscribe();
  }

  refreshStore() {
    this._authService.currentUser$
      .pipe(
        distinctUntilChanged(),
        switchMap((user) => {
          if (!user) return EMPTY;
          return this.getMeetingsWithFilters$({ size: 100000 }).pipe(
            take(1),
            map((res) => res.data),
            tap(() => this._loading$$.next(true)),
          );
        }),
      )
      .subscribe((meetings) => {
        this._meetings$$.next(meetings);
        this._loading$$.next(false);
      });
  }

  getMeetingsWithFilters$(filters?: IFilterFindMeetings) {
    let endpoint = `${environment.API_URL}/meeting/meetings/v2`;
    if (filters) {
      endpoint += '?' + qs.stringify(filters);
    }
    return this._httpClient.get<{
      data: IMeetingResult[];
      pagination: { total: number; page: number; totalPages: number };
    }>(endpoint);
  }

  isLastMeetingOfCampaign$(meetingId: string): Observable<boolean | undefined> {
    return this.getOne(meetingId).pipe(
      switchMap((meeting) => {
        if (!meeting) return of(undefined);

        if (!meeting.campaignId)
          return of({
            data: [],
          });

        const filters: IFilterFindMeetings = {
          campaignIds: [meeting.campaignId],
        };

        return this.getMeetingsWithFilters$(filters);
      }),
      map((res) => res.data?.length ?? 0),
      map((count) => count <= 1),
    );
  }

  getOne(meetingId: string): Observable<IMeetingResult> {
    return this._httpClient.get<IMeetingResult>(
      this._ENDPOINT_URL + '/meetings/' + meetingId,
    );
  }

  getDraft(meetingId: string): Observable<IPrepareMeetingResult> {
    return this._httpClient.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetings/' + meetingId,
    );
  }

  getPrepare(meetingId: string): Observable<IPrepareMeetingResult> {
    return this._httpClient.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
    );
  }

  startOne$(meetingId: string): Observable<IMeetingResult> {
    return this._httpClient
      .post<IMeetingResult>(this._ENDPOINT_URL + '/meetingsScheduler/start', {
        id: meetingId,
      })
      .pipe(
        tap((meeting) => {
          this._notificationService.showSuccess('MEETING.FORCE_START_SUCCESS');
          this.refreshStore();
          return meeting;
        }),
      );
  }

  getMultiple(meetingIds: string[]): Observable<IMeetingResult[]> {
    const meetingRequests$ = combineLatest(
      meetingIds.map((meetingId) => this.getOne(meetingId)),
    );
    return meetingRequests$;
  }

  getPrepareMeetingAnswer(
    meetingId: string,
  ): Observable<IPrepareMeetingResult> {
    return this._httpClient.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
    );
  }

  getConductMeetingAnswer(
    meetingId: string,
  ): Observable<IConductMeetingResult> {
    return this._httpClient.get<IConductMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/conduct/' + meetingId,
    );
  }

  postMeetingAnswer(
    meetingId: string,
    questions: IParticipantAnswerPrepare[],
  ): Observable<IPrepareMeetingResult> {
    return this._httpClient.post<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
      { questions },
    );
  }

  postMeetingAnswerDraft(
    meetingId: string,
    questions: IParticipantAnswerPrepare[],
  ): Observable<IParticipantAnswerResult[]> {
    return this._httpClient.post<IParticipantAnswerResult[]>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId + '/draft',
      { questions },
    );
  }

  postConductMeetingAnswer(
    meetingId: string,
    payload: IPostMeetingConduct,
  ): Observable<IMeetingResult> {
    return this._httpClient.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/conduct/' + meetingId,
      payload,
    );
  }

  postSignMeeting(
    meetingId: string,
    payload: { signed: boolean; comment?: string },
  ): Observable<IMeetingResult> {
    return this._httpClient.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/sign/' + meetingId,
      payload,
    );
  }

  create$(meeting: ICreateMeeting): Observable<IMeetingResult> {
    return this._httpClient
      .post<IMeetingResult>(this._ENDPOINT_URL + '/meetings', meeting)
      .pipe(
        switchMap((meeting) => {
          return this._meetingTemplatesService
            .getTemplateById$(meeting.templateId)
            .pipe(map((template) => ({ meeting, template })));
        }),
        map(({ meeting, template }) => {
          this.posthogEvent$$.next({
            mutation: EDataMutation.CREATED,
            meeting,
            template,
          });
          this.refreshStore();

          return meeting;
        }),
      );
  }

  patch(
    meetingId: string,
    meetingToPatch: IPatchMeeting,
  ): Observable<IMeetingResult> {
    return this._httpClient
      .patch<IMeetingResult>(
        `${this._ENDPOINT_URL}/meetings/${meetingId}`,
        meetingToPatch,
      )
      .pipe(tap(() => this.refreshStore()));
  }

  /**
   * Change the reviewer for a meeting
   * @param newReviewerUserId the user id of the next reviewer
   * @param meetingId
   * @param fromView will affect the toast message
   * @returns
   */
  patchReviewerUserIdByMeetingId$(
    newReviewerUserId: number,
    meetingId: string,
    fromView: 'meeting' | 'campaign' = 'campaign',
  ) {
    return this._httpClient
      .patch<IMeetingResult>(
        `${this._ENDPOINT_URL}/meetings/${meetingId}/reviewer/${newReviewerUserId}`,
        {},
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.' +
              (fromView === 'campaign'
                ? 'REVIEWER_HAS_BEEN_UPDATED_CAMPAIGN_TOAST'
                : 'REVIEWER_HAS_BEEN_UPDATED_MEETING_TOAST'),
          );
          this.refreshStore();
        }),
      );
  }

  delete$(id: string): Observable<IMeetingResult> {
    return this._httpClient
      .delete<IMeetingResult>(`${this._ENDPOINT_URL}/meetings/${id}`)
      .pipe(
        tap(() => {
          this.refreshStore();
          this._notificationService.showSuccess(
            'MEETING.DELETE_MEETING_SUCCESS',
          );
        }),
      );
  }

  computeTimeLineSteps(meeting: IMeeting, viewRole: EParticipantRoles): Step[] {
    let activeStateFound = false;
    const steps = [...meetingTimelineAdminSteps];
    for (let i = 0; i < meetingTimelineAdminSteps.length; i++) {
      if (activeStateFound) {
        steps[i].state = 'next';
      } else {
        if (
          meetingTimelineAdminSteps[i].possibleStatuses.includes(meeting.status)
        ) {
          activeStateFound = true;
          steps[i].state = 'active';
        } else {
          steps[i].state = 'done';
        }
      }
    }
    const prepareStep = steps.find(
      (step) => step.name === EMeetingStepName.PREPARE,
    );
    const signStep = steps.find((step) => step.name === EMeetingStepName.SIGN);

    this._meetingTimelineService.computeStepExtras(
      prepareStep,
      meeting.status,
      meeting,
      viewRole,
      'prepare',
    );
    this._meetingTimelineService.computeStepExtras(
      signStep,
      meeting.status,
      meeting,
      viewRole,
      'sign',
    );
    return steps;
  }

  downloadMeetingExport(meeting: IMeetingResult): void {
    const endpoint = `meeting/meetings/${meeting.id}/download`;
    const posthogConfig: IPostHogEventConfig = {
      event: this._emitDownloadedReportEvent$$,
      payload: meeting.id,
    };
    this._filesService.downloadFile(endpoint, 'pdf', undefined, posthogConfig);
  }

  openDeleteMeetingModal$(
    meetingId: string,
  ): Observable<{ deleted: boolean; isLast: boolean }> {
    return this.isLastMeetingOfCampaign$(meetingId).pipe(
      take(1),
      switchMap((isLastMeetingOfCampaign) => {
        return this._modalsService
          .openConfirmationModal$(
            isLastMeetingOfCampaign
              ? MODAL_CONFIGS.DELETE_MEETING_THUS_CAMPAIGN_MODAL_CONFIG
              : MODAL_CONFIGS.DELETE_MEETING_MODAL_CONFIG,
          )
          .pipe(
            switchMap((confirm) => {
              if (!confirm)
                return of({ deleted: false, isLast: isLastMeetingOfCampaign });
              return this.delete$(meetingId).pipe(
                map((res) => ({
                  deleted: !!res,
                  isLast: isLastMeetingOfCampaign,
                })),
              );
            }),
          );
      }),
    );
  }

  previewTemplateByMeetingId$(
    meetingId: string,
    config?: IPreviewTemplateModalConfig,
  ) {
    return this.getOne(meetingId).pipe(
      take(1),
      map((meeting) => {
        const answers: IParticipantAnswerResult[] =
          meeting.participants[0].answers;

        const questionsFromAnswer: ITemplateQuestions[] = answers.map((a) => {
          return {
            id: a.question?.id,
            companyId: a.question?.companyId,
            createdAt: a.question?.createdAt,
            updatedAt: a.question?.updatedAt,
            archivedAt: a.question?.archivedAt,
            order: a.questionOrder,
            target: a.questionTarget,
            templateId: meeting?.template?.id,
            questionId: a.questionId,
            question: a.question,
          };
        });

        const templateRef: ITemplateResult = {
          ...meeting?.template,
          questions: questionsFromAnswer,
          notes: meeting.notes,
        };

        if (!templateRef) return EMPTY;
        const previewTemplateCongif: IPreviewTemplateModalConfig = {
          template: {
            ...templateRef,
            title: { [meeting.locale]: meeting.title },
            description: { [meeting.locale]: meeting.description },
          },
          locale: meeting.locale,
          includeAnswerPreview: true,
          displaySelectTemplateButton: false,
        };
        return this._meetingTemplatesService.previewTemplate$(
          config ?? previewTemplateCongif,
        );
      }),
    );
  }
}
