import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Company } from '@models/company.model';
import {
  IFindRoleFilter,
  ModulePermission,
  Permission,
  UserPermissions,
  UserRole,
} from '@models/permissions.model';
import * as qs from 'qs';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  retry,
  switchMap,
  take,
} from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  _userPermissions: Permission[] = [];
  _company: Company;
  _isReady$$ = new BehaviorSubject<boolean>(false);

  private _roles$$: BehaviorSubject<UserRole[] | undefined> =
    new BehaviorSubject<UserRole[] | undefined>([]);
  roles$: Observable<UserRole[] | undefined> = this._roles$$.asObservable();

  constructor(
    private http: HttpClient,
    private readonly _authService: AuthenticationService,
    private readonly _companyService: CompanyService,
  ) {
    this._authService.currentUser$
      .pipe(map((user) => user?.role?.permissions))
      .subscribe((userPermissions) => {
        this._userPermissions = userPermissions ?? [];
        this._isReady$$.next(true);
      });
    this._companyService.currentCompany$.subscribe((company) => {
      this._company = company;
    });
    this.initStore();
  }

  initStore() {
    this._authService.currentUser$
      .pipe(
        distinctUntilChanged(),
        switchMap((user) => {
          if (!user) return EMPTY;
          const isAdmin = user.role?.permissions.includes('admin:access');
          if (!isAdmin) return EMPTY;
          return this._getRoles$({ userCount: true });
        }),
        take(1),
      )
      .subscribe((roles) => this._roles$$.next(roles));
  }

  refreshRoles() {
    this.initStore();
  }

  validateAllPermissions(permissions: Permission[]): boolean {
    return permissions.every((permission) =>
      this._userPermissions.includes(permission),
    );
  }

  validateOneOfPermissions(permissions: UserPermissions): boolean {
    return permissions.some((permission) =>
      this._userPermissions.includes(permission),
    );
  }

  companyHasModules(modules: ModulePermission[]): boolean {
    return this._company && modules.every((module) => this._company[module]);
  }

  companyHasModules$(modules: ModulePermission[]): Observable<boolean> {
    return this._companyService.currentCompany$.pipe(
      map((company) => modules.every((module) => company[module])),
    );
  }

  companyHasOneOfModules(modules: ModulePermission[]): boolean {
    return this._company && modules.some((module) => this._company[module]);
  }

  companyHasFeature(flag: string): boolean {
    return this._company.featureFlags.hasOwnProperty(flag);
  }

  companyHasFeatureVersion(flag: string, version: string): boolean {
    return (
      this._company.featureFlags.hasOwnProperty(flag) &&
      this._company.featureFlags[flag] === version
    );
  }

  companyHasOneOfFeatureVersions(flag: string, versions: string[]): boolean {
    return (
      this._company.featureFlags.hasOwnProperty(flag) &&
      versions.includes(this._company.featureFlags[flag])
    );
  }

  private _getRoles$(filters?: IFindRoleFilter): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(
      `${environment.API_URL}/core/roles` + '?' + qs.stringify(filters),
    );
  }

  getRole(roleId): Observable<UserRole> {
    return this.http.get<UserRole>(
      `${environment.API_URL}/core/roles/` + roleId,
    );
  }

  updateRole(role: UserRole): Observable<void> {
    const { id, name, permissions } = role;
    return this.http
      .patch<void>(`${environment.API_URL}/core/roles/${id}`, {
        name,
        permissions,
      })
      .pipe(retry(1));
  }

  createRole(role: UserRole): Observable<number> {
    const { name, permissions } = role;
    return this.http
      .post<UserRole>(`${environment.API_URL}/core/roles`, {
        name,
        permissions,
      })
      .pipe(
        retry(1),
        map((data) => data.id),
      );
  }

  deleteRole(id: number, fallbackId: number): Observable<boolean> {
    return this.http
      .delete(
        `${environment.API_URL}/core/roles/${id}?fallbackRoleId=${fallbackId}`,
        {
          observe: 'response',
        },
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }
}
