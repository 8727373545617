import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Company, CompanySettings } from '@models/company.model';
import { CompanyLogo } from '@models/companyLogo.model';
import { TalentLocale } from '@models/locales';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { map, retry, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { NotificationService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private _currentCompany$$: BehaviorSubject<Company | undefined> =
    new BehaviorSubject<Company | undefined>(undefined);
  readonly currentCompany$ = this._currentCompany$$.asObservable();

  get currentCompany() {
    return this._currentCompany$$.value;
  }

  availableLocales: TalentLocale[] = ['en', 'fr'];

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _authService: AuthenticationService,
    private readonly _notificationsService: NotificationService,
  ) {
    this._authService.currentUser$
      .pipe(
        switchMap((user) => {
          if (!user) return EMPTY;
          return this.getCompany(user.companyId);
        }),
      )
      .subscribe((company) => this._currentCompany$$.next(company));
  }

  getCompany(companyId: number): Observable<Company> {
    return this._httpClient
      .get<Company>(`${environment.API_URL}/core/companies/${companyId}`)
      .pipe(
        switchMap((company: Company) => {
          if (!company) return of(company);
          return this.getCompanyLocales(company.id).pipe(
            map((companyLocales) => {
              const companyWithLocales: Company = {
                ...company,
                locales:
                  companyLocales.length > 2 ? companyLocales : ['fr', 'en'],
              };
              return companyWithLocales;
            }),
          );
        }),
      );
  }

  getCompanyLogo(companyId: number): Observable<CompanyLogo> {
    return this._httpClient
      .get<CompanyLogo>(
        `${environment.API_URL}/core/companies/${companyId}/logo`,
      )
      .pipe(retry(1));
  }

  getCompanyLocales(companyId: number): Observable<TalentLocale[]> {
    return this._httpClient.get<TalentLocale[]>(
      `${environment.API_URL}/core/companies/${companyId}/locales`,
    );
  }

  patchCompany$(payload: Partial<CompanySettings>): Observable<boolean> {
    return this.currentCompany$
      .pipe(
        map((company) => company.id),
        take(1),
        switchMap((id) =>
          this._httpClient.patch<Company>(
            `${environment.API_URL}/admin/companies/${id}`,
            payload,
            { observe: 'response' },
          ),
        ),
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
        tap((success) => {
          if (success)
            this._notificationsService.showSuccess('COMPANY.CONFIRM_UPDATE');
        }),
      );
  }
}
