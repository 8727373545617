import { i18nObject } from '@models/locales';
import { IMeetingNote } from './meeting.model';

export interface ITemplate {
  id: string;
  companyId: number;
  createdAt: Date;
  updatedAt: Date | null;
  archivedAt: Date | null;
  title: i18nObject;
  description?: i18nObject;
  typeId: string;
}

export interface ITemplateQuestions {
  id: string;
  companyId: number;
  createdAt: Date;
  updatedAt: Date | null;
  archivedAt: Date | null;
  order: number;
  target: EQuestionsTargets;
  templateId: string;
  questionId: string;
}

export enum EQuestionsTargets {
  REVIEWEE = 'REVIEWEE',
  REVIEWER = 'REVIEWER',
  BOTH = 'BOTH',
}

export interface ICreateTemplate {
  companyId?: number;
  typeId: string;
  title: i18nObject;
  description: i18nObject;
  questions: ITemplateQuestion[];
  notes: ICreateTemplateNote[];
}

export interface ITemplateQuestion {
  questionId: string;
  order: number;
  target: EQuestionsTargets;
}

export interface IPatchTemplate {
  title: i18nObject;
  description: i18nObject;
  questions: ITemplateQuestion[];
  notes: IPatchTemplateNote[];
}

export interface ICreateTemplateNote {
  content: i18nObject;
  order: number;
}
export interface IPatchTemplateNote {
  id?: string;
  content: i18nObject;
  order: number;
}

export interface IFindTemplate {
  companyId?: number;
  typeId?: string;
}

export interface IGetTemplate {}

export interface IArchiveTemplate {}

export interface ITemplateResult extends ITemplate {
  questions: ITemplateQuestions[];
  notes: IPatchTemplateNote[] | IMeetingNote[];
}
