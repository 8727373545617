import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { CoreModule } from '@core/core.module';
import { GlobalErrorHandler } from '@core/errorHandler/dev-error-handler';
import { PageUrlHeaderInterceptor } from '@core/interceptors/page-url.interceptor';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { DateAdapterCustom } from '@shared/adapters/date-adapter';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatDialogModule,
    CoreModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: (_) => localStorage.getItem('token'),
        allowedDomains: [environment.WHITELISTED_DOMAIN],
      },
    }),
    ToastrModule.forRoot(),
    MatNativeDateModule,
    TranslocoRootModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: DateAdapterCustom },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PageUrlHeaderInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: environment.production
        ? Sentry.createErrorHandler({ showDialog: false })
        : undefined,
    },
    {
      provide: ErrorHandler,
      useClass: environment.production ? undefined : GlobalErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
