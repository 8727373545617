import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITemplateResult } from '@meeting/models/meeting-template.model';
import { TalentLocale } from '@models/locales';
import { BehaviorSubject } from 'rxjs';

export interface IPreviewTemplateModalConfig {
  template: ITemplateResult;
  includeAnswerPreview?: boolean;
  displaySelectTemplateButton?: boolean;
  locale: TalentLocale;
}
@Component({
  selector: 'preview-template-modal',
  styleUrls: ['./preview-template-modal.component.scss'],
  template: `
    <meeting-template-readonly
      [isIntroductionExpanded]="true"
      [template]="data.template"
      [locale]="locale$"
      [includeAnswerPreview]="data.includeAnswerPreview"
    ></meeting-template-readonly>
    <div
      class="flex items-center justify-between mt-auto ml-3 mr-3 pt-3 mb-3 hr-top"
    >
      <button mat-button color="primary" class="pr-5" (click)="close()">
        <span class="text-left back-label">
          {{ 'MEETING.CLOSE_PREVIEW' | transloco | capitalize }}
        </span>
      </button>
      <button
        mat-raised-button
        *ngIf="data.displaySelectTemplateButton"
        color="primary"
        class="ml-5"
        (click)="valid()"
      >
        {{ 'MEETING.SELECT_THIS_MEETING' | transloco | capitalize }}
        <i class="material-icons">navigate_next</i>
      </button>
    </div>
  `,
})
export class PreviewTemplateModalComponent {
  locale$$ = new BehaviorSubject<TalentLocale | undefined>(undefined);
  locale$ = this.locale$$.asObservable();
  constructor(
    public dialogRef: MatDialogRef<PreviewTemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IPreviewTemplateModalConfig,
  ) {
    this.locale$$.next(data.locale);
  }

  close() {
    this.dialogRef.close();
  }

  valid() {
    this.dialogRef.close(true);
  }
}
