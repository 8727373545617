import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { IQuestionCategory } from '@meeting/models/meeting-question-category.model';
import { IQuestionResult } from '@meeting/models/meeting-question.model';
import { ITemplateResult } from '@meeting/models/meeting-template.model';
import { ETypes } from '@meeting/models/meeting-type.interface';
import {
  EMeetingStatuses,
  EParticipantRoles,
  ICampaignResult,
  IMeetingResult,
  IParticipant,
  IPrepareMeetingResult,
} from '@meeting/models/meeting.model';
import { CampaignsService } from '@meeting/services/campaigns.service';
import { MeetingCategoriesService } from '@meeting/services/meeting-category.service';
import { MeetingTemplatesService } from '@meeting/services/meeting-template.service';
import { MeetingTypesService } from '@meeting/services/meeting-type.service';
import {
  ISendDownloadedReportEventPayload,
  MeetingService,
} from '@meeting/services/meeting.service';
import { Company } from '@models/company.model';
import { User } from '@models/user.model';
import posthog from 'posthog-js';
import {
  BehaviorSubject,
  EMPTY,
  Subject,
  Subscriber,
  Subscription,
  combineLatest,
} from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { CompanyService } from './company.service';

export enum Event {
  USER = 'User',
  MEETING = 'Meeting',
  TEMPLATE = 'Template',
  QUESTION = 'Question',
  INTERVIEW_REPORT = 'Interview Report',
  CAMPAIGN_REPORT = 'Campaign Reports',
  CAMPAIGN = 'Campaign',
}
export enum EDataMutation {
  CREATED = 'Created',
  MODIFIED = 'Modified',
  DUPLICATED = 'Duplicated',
  DELETED = 'Deleted',
  ADD_PARTICIPANTS = 'Add Participants',
  EXTEND_END_DATE = 'Extend Date',
}

export enum EMeetingMutation {
  PREPARED = 'Prepared',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  DRAFT = 'Draft',
  CHANGE_REVIEWER = 'Change Reviewer',
}

export enum EUserRole {
  ADMIN = 'admin',
  USER = 'user',
}

enum EAccessOrigin {
  MY_SILAE = 'my silae',
  EMAIL = 'email',
  DIRECT = 'direct',
  RH_SUITE = 'rh suite',
}

export interface IPostHogEventConfig {
  event: Subject<any | void> | BehaviorSubject<any | void>;
  payload?: any;
}

@Injectable({ providedIn: 'root' })
export class PostHogService {
  private apiKey = environment.POST_HOG_API_KEY;
  private apiHost = environment.POST_HOG_HOST;
  private subscribers = new Subscriber();

  public emitAccessEvent$$ = new Subject<void>();
  public emitQuestionEvent$$ = new Subject<{
    mutation: EDataMutation;
    question: IQuestionResult;
  }>();
  public emitTemplateEvent$$ = new Subject<{
    mutation: EDataMutation;
    template: ITemplateResult;
  }>();
  public emitMeetingEvent$$ = new Subject<{
    mutation: EDataMutation | EMeetingMutation;
    meeting: IMeetingResult | IPrepareMeetingResult;
    template: ITemplateResult;
  }>();

  public emitCampaignEvent$$ = new Subject<{
    mutation: EDataMutation;
    campaign: ICampaignResult;
  }>();

  public emitDownloadedCampaignReportsEvent$$ = new Subject<void>();
  public emitCampaignExtendDateEvent$$ = new Subject<string>();
  public emitCampaignAddParticipantsEvent$$ = new Subject<{
    campaignId: string;
    createdMeetings: string[];
  }>();

  constructor(
    private readonly _meetingService: MeetingService,
    private readonly _router: Router,
    private readonly _jwt: JwtHelperService,
    private readonly _authService: AuthenticationService,
    private readonly _companyService: CompanyService,
    private readonly _meetingCategoriesService: MeetingCategoriesService,
    private readonly _meetingTypesService: MeetingTypesService,
    private readonly _campaignsService: CampaignsService,
    private readonly _meetingTemplatesService: MeetingTemplatesService,
  ) {
    this.initPostHog();

    this.subscribers.add(this.initAccessEvent$());
    this.subscribers.add(this.initQuestionEvent$());
    this.subscribers.add(this.initTemplateEvent$());
    this.subscribers.add(this.initMeetingEvent$());
    this.subscribers.add(this.initDownloadedCampaignReportsEvent$());
    this.subscribers.add(this.initCampaignEvents$());
    this.subscribers.add(this.initExtendCampaignDateEvent$());
    this.subscribers.add(this.initAddCampaignParticipantsEvent$());

    this._meetingService.sendDownloadedReportEvent$$
      .pipe()
      .subscribe(
        (payload) => payload && this.sendDownloadedReportEvent(payload),
      );

    this._campaignsService.posthogEvent$$.subscribe(
      (posthogEvent) =>
        posthogEvent && this.emitCampaignEvent$$.next(posthogEvent),
    );
    this._campaignsService.addParticipantsPosthogEvent$$.subscribe(
      (posthogEvent) =>
        posthogEvent &&
        this.emitCampaignAddParticipantsEvent$$.next(posthogEvent),
    );
    this._campaignsService.downloadedCampaignReportsEvent$$.subscribe(
      (posthogEvent) =>
        this.emitDownloadedCampaignReportsEvent$$.next(posthogEvent),
    );
    this._meetingService.posthogEvent$$.subscribe(
      (posthogEvent) =>
        posthogEvent && this.emitMeetingEvent$$.next(posthogEvent),
    );
    this._meetingTemplatesService.posthogEvent$$.subscribe(
      (posthogEvent) =>
        posthogEvent && this.emitTemplateEvent$$.next(posthogEvent),
    );
  }

  private initAccessEvent$(): Subscription {
    return this.emitAccessEvent$$
      .pipe(
        switchMap(() =>
          combineLatest([
            this._authService.currentUser$.pipe(filter((user) => !!user)),
            this._companyService.currentCompany$,
            this._router.events.pipe(
              filter(
                (event) => event instanceof RoutesRecognized && event.id === 1,
              ),
              map((event) => event as RoutesRecognized),
            ),
          ]).pipe(take(1)),
        ),
        switchMap(([user, company, routesRecognized]) => {
          if (!routesRecognized) {
            return EMPTY;
          }
          return this._router.events.pipe(
            tap((event) => {
              if (event instanceof NavigationEnd) {
                this.sendAccessEvent(user, company, routesRecognized);
              }
            }),
          );
        }),
      )
      .subscribe();
  }

  private initQuestionEvent$(): Subscription {
    return this.emitQuestionEvent$$
      .pipe(
        switchMap(({ mutation, question }) => {
          return combineLatest([
            // should not inject this service in posthog, should deplace this logic INSIDE this service
            this._meetingCategoriesService
              .getCategoryById$(question.categoryId)
              .pipe(map((category) => ({ mutation, category }))),
            this._companyService.currentCompany$,
          ]);
        }),
        tap(([{ mutation, category }, company]) =>
          this.sendQuestionEvent(mutation, category, company),
        ),
      )
      .subscribe();
  }

  private initTemplateEvent$(): Subscription {
    return this.emitTemplateEvent$$
      .pipe(
        switchMap(({ mutation, template }) => {
          return combineLatest([
            this._meetingTypesService
              .getTypeById$(template.typeId)
              .pipe(map((type) => ({ mutation, template, type }))),
            this._companyService.currentCompany$,
          ]);
        }),
        tap(([{ mutation, template, type }, company]) =>
          this.sendTemplateEvent(
            mutation,
            {
              id: template.id,
              type: type.type,
              name: template.title.fr,
              updatedAt: template.updatedAt,
            },
            company,
          ),
        ),
      )
      .subscribe();
  }

  private initCampaignEvents$(): Subscription {
    return this.emitCampaignEvent$$
      .pipe(
        switchMap(({ mutation, campaign }) => {
          return this._companyService.currentCompany$.pipe(
            map((company) => ({ campaign, mutation, company })),
          );
        }),
        switchMap(({ campaign, mutation, company }) => {
          this.sendCampaignEvent(
            mutation,
            {
              campaignId: campaign.id,
              totalMeetings: campaign.meetings.count ?? 0,
            },
            company,
          );

          this._meetingService.refreshStore();
          return this._meetingService.meetings$.pipe(
            map((meetings) =>
              meetings.filter((meeting) =>
                (campaign.meetings?.ids ?? []).includes(meeting.id),
              ),
            ),
            map((meetings) => ({ meetings, company, campaign })),
            switchMap(({ meetings }) => {
              return this._authService.currentUser$.pipe(
                map((user) => ({ meetings, company, campaign, user })),
              );
            }),
          );
        }),
      )
      .subscribe(({ meetings, company, campaign, user }) => {
        for (const meeting of meetings) {
          this.sendMeetingEvent(
            EDataMutation.CREATED,
            {
              campaignId: campaign.id,
              startDate: meeting.startDate,
              endDate: meeting.endDate,
              status: meeting.status,
              meetingId: meeting.id,
              templateName: campaign.title,
              signatureStatus: '',
              userRole: this.getUserRole(user),
              participantRole: '',
            },
            company,
          );
        }
      });
  }

  private initMeetingEvent$(): Subscription {
    return this.emitMeetingEvent$$
      .pipe(
        switchMap(({ mutation, meeting, template }) => {
          return combineLatest([
            this._authService.currentUser$.pipe(
              map((user) => ({ meeting, mutation, template, user })),
            ),
            this._companyService.currentCompany$,
          ]);
        }),
        tap(([{ meeting, template, mutation, user }, company]) => {
          let participant: IParticipant;
          if (this.isPrepareMeeting(meeting)) {
            participant = meeting.participant;
          } else {
            participant = meeting.participants.find(
              (p) => (p.userId ?? p.user?.id) === user.id,
            );
          }

          this.sendMeetingEvent(
            mutation,
            {
              participantRole: this.getParticipantRole(participant),
              signatureStatus: this.getParticipantSignatureStatus(participant),
              templateName: template.title.fr,
              startDate: meeting.startDate,
              endDate: meeting.endDate,
              userRole: this.getUserRole(user),
              meetingId: meeting.id,
              status: meeting.status,
              campaignId: meeting.campaignId,
            },
            company,
          );
        }),
      )
      .subscribe();
  }

  private initDownloadedCampaignReportsEvent$(): Subscription {
    return this.emitDownloadedCampaignReportsEvent$$
      .pipe(
        switchMap(() => {
          return this._companyService.currentCompany$;
        }),
        tap((company) => {
          this.sendDownloadedCampaignReportsEvent(company);
        }),
      )
      .subscribe();
  }

  private initExtendCampaignDateEvent$(): Subscription {
    return this.emitCampaignExtendDateEvent$$
      .pipe(
        switchMap((campaignId) => {
          return this._companyService.currentCompany$.pipe(
            map((company) => ({ campaignId, company })),
          );
        }),
        tap(({ campaignId, company }) => {
          this.sendCampaignExtendDateEvent(campaignId, company);
        }),
      )
      .subscribe();
  }

  private initAddCampaignParticipantsEvent$(): Subscription {
    return this.emitCampaignAddParticipantsEvent$$
      .pipe(
        switchMap(({ campaignId, createdMeetings }) => {
          return this._companyService.currentCompany$.pipe(
            map((company) => ({ campaignId, createdMeetings, company })),
          );
        }),
        tap(({ campaignId, createdMeetings, company }) => {
          this.sendCampaignAddParticipantsEvent(
            campaignId,
            createdMeetings,
            company,
          );
        }),
        switchMap(({ createdMeetings, company, campaignId }) => {
          this._meetingService.refreshStore();

          return this._meetingService.meetings$.pipe(
            map((meetings) =>
              meetings.filter((meeting) =>
                createdMeetings.includes(meeting.id),
              ),
            ),
            map((meetings) => ({ meetings, company, campaignId })),
          );
        }),
        switchMap(({ campaignId, meetings, company }) =>
          this._campaignsService
            .getOneCampaign$(campaignId)
            .pipe(map((campaign) => ({ campaign, meetings, company }))),
        ),
        switchMap(({ campaign, meetings, company }) => {
          return this._authService.currentUser$.pipe(
            map((user) => ({ meetings, company, campaign, user })),
          );
        }),
      )
      .subscribe(({ meetings, company, campaign, user }) => {
        for (const meeting of meetings) {
          this.sendMeetingEvent(
            EDataMutation.CREATED,
            {
              campaignId: campaign.id,
              startDate: meeting.startDate,
              endDate: meeting.endDate,
              status: meeting.status,
              meetingId: meeting.id,
              templateName: campaign.title,
              signatureStatus: '',
              userRole: this.getUserRole(user),
              participantRole: '',
            },
            company,
          );
        }
      });
  }

  private initPostHog() {
    if (this.apiKey) {
      posthog.init(this.apiKey, {
        api_host: this.apiHost,
        autocapture: true,
        name: 'Talent',
      });
    }
  }

  private sendAccessEvent(
    user: User,
    company: Company,
    routesRecognized: RoutesRecognized,
  ): void {
    posthog.identify(user.email.toLocaleLowerCase(), {
      email: user.email.toLocaleLowerCase(),
      firstname: user.firstname,
      lastname: user.lastname,
    });

    posthog.capture(`${Event.USER} Accessed Talent`, {
      origin: this.getUserOrigin(
        routesRecognized?.state?.root?.firstChild?.queryParams,
      ),
      user_role: this.getUserRole(user),
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
    });
  }

  private sendQuestionEvent(
    mutation: EDataMutation,
    category: IQuestionCategory,
    company: Company,
  ): void {
    posthog.capture(`${Event.QUESTION} ${mutation}`, {
      category: category.name.fr,
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
    });
  }

  private sendTemplateEvent(
    mutation: EDataMutation,
    template: {
      id: string;
      type: ETypes;
      name: string;
      updatedAt: Date;
    },
    company: Company,
  ): void {
    posthog.capture(`${Event.TEMPLATE} ${mutation}`, {
      template_id: template.id,
      template_type: template.type,
      template_name: template.name,
      updated_at: template.updatedAt ?? '',
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
    });
  }

  private sendMeetingEvent(
    mutation: EDataMutation | EMeetingMutation,
    data: {
      templateName: string;
      signatureStatus: 'approved' | 'rejected' | '';
      startDate: Date;
      endDate: Date;
      userRole: 'user' | 'admin';
      participantRole: string;
      meetingId: string;
      status: EMeetingStatuses;
      campaignId: string | null;
    },
    company: Company,
  ): void {
    posthog.capture(`${Event.MEETING} ${mutation}`, {
      template_name: data.templateName,
      signature_status: data.signatureStatus,
      start_date: data.startDate,
      end_date: data.endDate,
      user_role: data.userRole,
      participant_role: data.participantRole,
      meeting_id: data.meetingId,
      meeting_status: data.status.toLowerCase(),
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
      campaign_id: data.campaignId,
    });
  }

  private sendCampaignEvent(
    mutation: EDataMutation,
    data: {
      campaignId: string;
      totalMeetings: number;
    },
    company: Company,
  ): void {
    posthog.capture(`${Event.CAMPAIGN} ${mutation}`, {
      campaign_id: data.campaignId,
      total_meetings: data.totalMeetings,
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
    });
  }

  private sendCampaignExtendDateEvent(
    campaignId: string,
    company: Company,
  ): void {
    posthog.capture(`${Event.CAMPAIGN} ${EDataMutation.EXTEND_END_DATE}`, {
      campaign_id: campaignId,
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
    });
  }

  private sendCampaignAddParticipantsEvent(
    campaignId: string,
    createdMeetings: string[],
    company: Company,
  ): void {
    posthog.capture(`${Event.CAMPAIGN} ${EDataMutation.ADD_PARTICIPANTS}`, {
      campaign_id: campaignId,
      created_meetings: createdMeetings.length,
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
    });
  }

  private sendDownloadedReportEvent(
    payload: ISendDownloadedReportEventPayload,
  ): void {
    if (!payload) return;
    const action = 'Downloaded';

    posthog.capture(`${Event.INTERVIEW_REPORT} ${action}`, {
      participant_role: this.getParticipantRole(payload.participant),
      company_id: payload.company?.externalId,
      domain_id: payload.company?.domaineGuid,
    });
  }

  private sendDownloadedCampaignReportsEvent(company: Company): void {
    const action = 'Downloaded';
    posthog.capture(`${Event.CAMPAIGN_REPORT} ${action}`, {
      participant_role: EParticipantRoles.ALL,
      company_id: company?.externalId,
      domain_id: company?.domaineGuid,
    });
  }

  private getUserRole(user: User): EUserRole {
    if (user.role.permissions.includes('admin:access')) {
      return EUserRole.ADMIN;
    }
    return EUserRole.USER;
  }

  private getParticipantRole(participant?: IParticipant): string {
    const role = participant?.role;

    if (!role) {
      return '';
    }

    if (role === EParticipantRoles.ALL) {
      return '';
    }

    return role.toLowerCase();
  }

  private getParticipantSignatureStatus(
    participant?: IParticipant,
  ): 'approved' | 'rejected' | '' {
    if (!participant) {
      return '';
    }

    if (participant.signed) {
      return 'approved';
    }

    if (!participant.signed && !!participant.unSignedComment) {
      return 'rejected';
    }

    return '';
  }

  private isPrepareMeeting(
    meeting: IMeetingResult | IPrepareMeetingResult,
  ): meeting is IPrepareMeetingResult {
    return 'participant' in meeting;
  }

  private getUserOrigin(queryParams: object): EAccessOrigin {
    if (!queryParams || !this.isAccessLink(queryParams)) {
      return EAccessOrigin.DIRECT;
    }

    const token = this._jwt.decodeToken(
      queryParams['connectionContent[accessToken]'],
    );

    if (token?.iss?.includes('talent')) {
      return EAccessOrigin.EMAIL;
    }

    if (token?.iss?.includes('my.silae')) {
      return EAccessOrigin.MY_SILAE;
    }

    return EAccessOrigin.DIRECT;
  }

  private isAccessLink(queryParams: object): queryParams is {
    connectionMethod: string;
    'connectionContent[accessToken]': string;
  } {
    return (
      'connectionMethod' in queryParams &&
      'connectionContent[accessToken]' in queryParams
    );
  }
}
