import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { ModalsBaseService } from '@core/services/modals.service';
import { NotificationService } from '@core/services/notifications.service';
import { EDataMutation } from '@core/services/posthog.service';
import { environment } from '@env/environment';
import { IPreviewTemplateModalConfig } from '@meeting/components/preview-template-modal/preview-template-modal.component';
import {
  ICreateTemplate,
  IPatchTemplate,
  ITemplateResult,
} from '@meeting/models/meeting-template.model';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingTemplatesService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting/templates`;

  private templates$$: BehaviorSubject<ITemplateResult[] | undefined> =
    new BehaviorSubject<ITemplateResult[] | undefined>([]);
  templates$ = this.templates$$.asObservable();

  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  loading$ = this._loading$$.asObservable();

  posthogEvent$$: BehaviorSubject<
    | {
        mutation: EDataMutation;
        template: ITemplateResult;
      }
    | undefined
  > = new BehaviorSubject<
    | {
        mutation: EDataMutation;
        template: ITemplateResult;
      }
    | undefined
  >(undefined);

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _notificationService: NotificationService,
    private readonly _modalsService: ModalsBaseService,
    private readonly _authService: AuthenticationService,
  ) {
    this._authService.currentUser$
      .pipe(distinctUntilChanged())
      .subscribe((user) => {
        user && this._initStore();
      });
  }

  private _initStore() {
    this.refreshStore();
  }

  refreshStore() {
    this._authService.currentUser$
      .pipe(
        distinctUntilChanged(),
        switchMap((user) => {
          if (!user) return EMPTY;
          return this.getAll$().pipe(
            take(1),
            tap(() => this._loading$$.next(true)),
          );
        }),
      )
      .subscribe((templates) => {
        this.templates$$.next(templates), this._loading$$.next(false);
      });
  }

  getTemplateById$(templateId: string) {
    return this.templates$.pipe(
      map((templates) =>
        templates.find((template) => templateId.includes(template.id)),
      ),
    );
  }

  getAll$(): Observable<ITemplateResult[]> {
    return this._httpClient.get<ITemplateResult[]>(this._ENDPOINT_URL);
  }

  create$(template: ICreateTemplate): Observable<ITemplateResult> {
    return this._httpClient
      .post<ITemplateResult>(this._ENDPOINT_URL, template)
      .pipe(
        tap((templateCreated) => {
          this._notificationService.showSuccess('MEETING.ADD_TEMPLATE_SUCCESS');
          this.posthogEvent$$.next({
            mutation: EDataMutation.CREATED,
            template: templateCreated,
          });
          this.refreshStore();
        }),
        catchError((_err) => {
          this._notificationService.showError('ALERTS.GENERIC_ERROR');
          return of(undefined);
        }),
      );
  }

  patch$(
    templateId: string,
    templateToPatch: IPatchTemplate,
  ): Observable<ITemplateResult> {
    return this._httpClient
      .patch<ITemplateResult>(
        `${this._ENDPOINT_URL}/${templateId}`,
        templateToPatch,
      )
      .pipe(
        tap((templateUpdated) => {
          this._notificationService.showSuccess(
            'MEETING.UPDATE_TEMPLATE_SUCCESS',
          );
          this.posthogEvent$$.next({
            mutation: EDataMutation.MODIFIED,
            template: templateUpdated,
          });
          this.refreshStore();
        }),
        catchError((_err) => {
          this._notificationService.showError('ALERTS.GENERIC_ERROR');
          return of(undefined);
        }),
      );
  }

  delete$(templateId: string): Observable<ITemplateResult> {
    return this._httpClient
      .delete<ITemplateResult>(`${this._ENDPOINT_URL}/${templateId}`)
      .pipe(
        tap((templateDeleted) => {
          this._notificationService.showSuccess(
            'MEETING.DELETE_TEMPLATE_SUCCESS',
          );

          this.posthogEvent$$.next({
            mutation: EDataMutation.DELETED,
            template: templateDeleted,
          });

          this.refreshStore();
        }),
        catchError((_err) => {
          this._notificationService.showError('ALERTS.GENERIC_ERROR');
          return of(undefined);
        }),
      );
  }

  previewTemplate$(config: IPreviewTemplateModalConfig) {
    return this._modalsService.openPreviewTemplateModal$(config);
  }
}
