import { Injectable } from '@angular/core';
import { CapitalizeFirstPipe } from '@core/pipes/capitalize-first.pipe';
import { MeetingTimelineStep } from '@meeting/components/config/meeting-timeline-steps';
import {
  EMeetingStatuses,
  EParticipantRoles,
  IMeeting,
  IParticipantResult,
} from '@meeting/models/meeting.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class MeetingTimelineService {
  constructor(
    private transloco: TranslocoService,
    private _capitalizePipe: CapitalizeFirstPipe,
  ) {}

  computeStepExtras(
    step: MeetingTimelineStep,
    status: EMeetingStatuses,
    meeting: IMeeting,
    viewRole: EParticipantRoles, // Remplace currentUserId
    stepType: 'prepare' | 'sign',
  ): void {
    step.tooltip = '';
    step.textExtra = this.getTextExtra(status, stepType);

    const reviewer = this.getParticipantByRole(
      meeting,
      EParticipantRoles.REVIEWER,
    );
    const reviewee = this.getParticipantByRole(
      meeting,
      EParticipantRoles.REVIEWEE,
    );
    step.tooltip = this.getTooltip(
      status,
      stepType,
      reviewer,
      reviewee,
      viewRole,
    );
  }

  private getTextExtra(
    status: EMeetingStatuses,
    stepType: 'prepare' | 'sign',
  ): string {
    const statusesForOneOfTwo =
      stepType === 'prepare'
        ? [
            EMeetingStatuses.PREPARED_BY_REVIEWEE,
            EMeetingStatuses.PREPARED_BY_REVIEWER,
          ]
        : [
            EMeetingStatuses.FINISHED_BY_REVIEWEE,
            EMeetingStatuses.FINISHED_BY_REVIEWER,
          ];

    if (
      (status === EMeetingStatuses.STARTED && stepType === 'prepare') ||
      (status === EMeetingStatuses.READY_TO_SIGN && stepType === 'sign')
    ) {
      return '(0/2)';
    }

    if (statusesForOneOfTwo.includes(status)) {
      return '(1/2)';
    }

    return '';
  }

  private getTooltip(
    status: EMeetingStatuses,
    stepType: 'prepare' | 'sign',
    reviewer?: IParticipantResult,
    reviewee?: IParticipantResult,
    viewRole?: EParticipantRoles,
  ): string {
    if (
      (status === EMeetingStatuses.STARTED && stepType === 'prepare') ||
      (status === EMeetingStatuses.READY_TO_SIGN && stepType === 'sign')
    ) {
      return this.transloco.translate('MEETING.TIMELINE.TOOLTIP_WAITING_BOTH');
    }

    if (
      (status === EMeetingStatuses.PREPARED_BY_REVIEWEE &&
        stepType === 'prepare') ||
      (status === EMeetingStatuses.FINISHED_BY_REVIEWEE && stepType === 'sign')
    ) {
      return this.getTooltipForRevieweePreparation(reviewer, viewRole);
    }

    if (
      (status === EMeetingStatuses.PREPARED_BY_REVIEWER &&
        stepType === 'prepare') ||
      (status === EMeetingStatuses.FINISHED_BY_REVIEWER && stepType === 'sign')
    ) {
      return this.getTooltipForReviewerPreparation(reviewee, viewRole);
    }

    return '';
  }

  private getTooltipForRevieweePreparation(
    reviewer?: IParticipantResult,
    viewRole?: EParticipantRoles,
  ): string {
    if (viewRole === EParticipantRoles.ALL) {
      return this.transloco.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWER',
        {
          username: `${this._capitalizePipe.transform(reviewer.firstName)} ${reviewer.lastName}`,
        },
      );
    }

    if (viewRole === EParticipantRoles.REVIEWEE) {
      return this.transloco.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWER',
        {
          username: `${this._capitalizePipe.transform(reviewer.firstName)} ${reviewer.lastName}`,
        },
      );
    }

    if (viewRole === EParticipantRoles.REVIEWER) {
      return this.transloco.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_FOR_YOU',
      );
    }

    return '';
  }

  private getTooltipForReviewerPreparation(
    reviewee?: IParticipantResult,
    viewRole?: EParticipantRoles,
  ): string {
    if (viewRole === EParticipantRoles.ALL) {
      return this.transloco.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWEE',
        {
          username: `${this._capitalizePipe.transform(reviewee.firstName)} ${reviewee.lastName}`,
        },
      );
    }

    if (viewRole === EParticipantRoles.REVIEWER) {
      return this.transloco.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_REVIEWEE',
        {
          username: `${this._capitalizePipe.transform(reviewee.firstName)} ${reviewee.lastName}`,
        },
      );
    }

    if (viewRole === EParticipantRoles.REVIEWEE) {
      return this.transloco.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_FOR_YOU',
      );
    }

    return '';
  }

  private getParticipantByRole(
    meeting: IMeeting,
    role: EParticipantRoles,
  ): IParticipantResult | undefined {
    return meeting.participants.find((user) => user.role === role);
  }
}
